<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('ASIDE.PRODUCTS') }}
    </p>
    <section class="filter-part content-margin d-flex">
      <div class="form-item">
        <label>{{ $t('MANAGE_PRODUCT.SELECT_CATEGORY') }}</label>
        <div class="m-b-15 icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('MANAGE_PRODUCT.SELECT_PROVIDER') }}</label>
        <div class="m-b-15 icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item p-relative">
        <label>{{
          $t('TOURISM_PROFIT_PERCENTAGES.MARKETER')
        }}</label>
        <vue-select
          :options="company.options"
          class="input cus-select cus-select2 m-b-15"
          v-model="company.selected"
          @input="changeCompany"
        ></vue-select>
      </div>
    </section>
    <section class="filter-part content-margin d-flex justify-content-between">
      <div class="filter-form-group">
        <div class="d-flex justify-content-between form-block">
          <div class="operation-name">
            <label>{{ $t('MANAGE_SHANIV.OPERATION_NAME') }}</label>
            <input type="text" class="input" v-model="package_data.name" />
          </div>
          <div class="price-div">
            <label>{{ $t('COMMON.PRICE') }}</label>
            <input type="text" class="input" v-model="package_data.price" />
          </div>
          <div class="type-operation">
            <label>{{ $t('MANAGE_SHANIV.TYPE_OF_OPERATION') }}</label>
            <vue-select
              :options="test.options"
              class="input cus-select"
              v-model="test.selected"
              :searchable="false"
            >
            </vue-select>
          </div>
        </div>
        <div class="d-flex justify-content-between form-block">
          <div class="date-selector">
            <label>{{ $t('REPORT_SALES.START_DATE') }}</label>
            <div class="d-flex align-items-center p-relative m-b-15">
              <Datepicker
                :format="format"
                class="input cus-datepicker"
                :language="languages[currentLang]"
                v-model="package_data.start_date"
              ></Datepicker>
              <img
                src="/assets/img/icons/calendar-alt-light.svg"
                alt=""
                class="calendar-img"
              />
            </div>
          </div>
          <div class="date-selector">
            <label>{{ $t('REPORT_SALES.END_DATE') }}</label>
            <div class="d-flex align-items-center p-relative m-b-15">
              <Datepicker
                :format="format"
                class="input cus-datepicker"
                :language="languages[currentLang]"
                v-model="package_data.end_date"
              ></Datepicker>
              <img
                src="/assets/img/icons/calendar-alt-light.svg"
                alt=""
                class="calendar-img"
              />
            </div>
          </div>
          <div class="time-selector">
            <label>{{ $t('MANAGE_SHANIV.START_TIME') }}</label>
            <div class="d-flex align-items-center p-relative m-b-15">
              <vue-select
                :options="start_hour.options"
                class="input cus-select hide-arrow-icon"
                v-model="package_data.start_time"
                :searchable="false"
              >
              </vue-select>
              <img src="/assets/img/icons/clock.png" alt="" class="clock-img" />
            </div>
          </div>
          <div class="time-selector">
            <label>{{ $t('MANAGE_SHANIV.END_TIME') }}</label>
            <div class="d-flex align-items-center p-relative m-b-15">
              <vue-select
                :options="end_hour.options"
                class="input cus-select hide-arrow-icon"
                v-model="package_data.end_time"
                :searchable="false"
              >
              </vue-select>
              <img src="/assets/img/icons/clock.png" alt="" class="clock-img" />
            </div>
          </div>
        </div>
        <div v-for="(item, index) in package_data.products"  :key="index"
          class="d-flex justify-content-between align-items-center product-form">
          <div class="minus-btn pointer" @click="removeProduct(index)">
            <img src="/assets/img/icons/plus-light.svg" alt="" />
          </div>
          <!-- <img src="/new-assets/img/אנטי-רטסט-ירוק.png" alt="" class="product-img"> -->
          <img :src="item.image" alt="" class="product-img">
          <div class="product-desc">
            <label>{{ $t('MANAGE_SHANIV.ITEM') }} {{index+1}}</label>
            <input type="text" class="input m-b-10" v-model="item.free_text" />
          </div>
          <div class="product-number">
            <label>{{ $t('GIFTCARD_PAYMENT_RESULT.QUANTITY') }}</label>
            <input type="text" class="input m-b-10" v-model="item.quantity" />
          </div>
        </div>
      </div>
      <div class="upload-part">
        <div class="d-flex justify-content-between align-items-center m-b-10">
          <p class="fs16 weight-700">{{ $t('ADS_MANAGEMENT.PREVIEW') }}</p>
          <div class="p-relative">
            <button class="primary-btn select-img_btn">
              {{ $t('MANAGE_SHANIV.SELECT_IMAGE') }}
            </button>
            <input class="tour-passport__file w-100" type="file" @change="handleImage" accept="image/*" ref="uplaod_file" multiple="multiple"/>
          </div>
        </div>
        <div class="upload-block">
          <!-- <div class="upload-content">
            <img src="/assets/img/icons/upload.png" alt="">
          </div> -->
          <div 
              class="upload-content p-relative"
              v-bind:style="{ backgroundImage: 'url(' + previewImage + ')' }">
              <img src="/assets/img/icons/upload.png" alt="" v-show="!isPreview">
              <!-- <input class="tour-passport__file w-100" type="file" @change="handleImage()" accept="image/*" ref="uplaod_file" multiple="multiple"/> -->
            </div>
          <div class="upload-bottom">
            <button class="primary-btn bg-blue add-basket_btn">
              <img src="/new-assets/icon/shopping-cart-light.svg" alt="" class="mx5">
              <p>{{ $t('MANAGE_SHANIV.ADD_TO_BASKET') }}</p>
            </button>
            <div class="cart-num">
              <img src="/new-assets/icon/plus-circle-solid.svg" alt="" class="circle-btn plus-circle">
              <input type="text" class="product-num" value="1">
              <img src="/new-assets/icon/minus-circle-solid.svg" alt="" class="circle-btn minus-circle">
            </div>
          </div>
        </div>
        <p class="text-center color-blue3 fs14 weight-700">{{ $t('MANAGE_SHANIV.VALID_UNTIL') }} 25/01/2020</p>
      </div>
    </section>
    <p class="filter-part content-margin weight-700">
      {{ $t('MANAGE_SHANIV.ADD_PRODUCTS_FROM_LIST') }}
    </p>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
            @input="debounceSearch"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="products_data.data"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="products_data.total"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
      >
        <template v-slot:[`item.add`]="{ item }">
          <div class="plus-btn pointer" @click="addProduct(item.id)">
            <img src="/assets/img/icons/plus-light.svg" alt="" />
          </div>
        </template>
        <template v-slot:[`item.BALANCE_original`]="{ item }">
          <div v-if="item.BALANCE_original == null || item.BALANCE_original <= 0" class="color-red4">
            {{ typeof item.BALANCE_original == 'number' ? item.BALANCE_original : '-' }}
          </div>
          <div v-else>
            {{ item.BALANCE_original }}
          </div>
        </template>
      </v-data-table>
    </section>
    <section class="bottom-action">
      <button class="primary-btn" @click="updatePackage()">
        {{ $t('COMMON.SAVE') }}
      </button>
      <button class="primary-btn bg-red1">
        {{ $t('MANAGE_SHANIV.VOID') }}
      </button>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'ShanivPackage',
  components: {
    Loading
  },
  computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      products_data: (state) => state.shaniv_management.products,
      package_data: (state) => state.shaniv_management.package_data,
      companyList: (state) => state.shaniv_management.companyList     
    }),
    packageId() {
      if (this.$route.name == "ShanivPackage") {
        return this.$route.params.id;
      } else {
        return false
      }
    },
  },
  data() {
    return {
      format: 'd MMMM yyyy',
      search: '',
      headers: [
        {
          text: 'PARTNAME',
          align: 'center',
          sortable: false,
          value: 'PARTNAME',
        },
        {
          text: 'PARTDES',
          align: 'center',
          sortable: false,
          value: 'PARTDES',
        },
        {
          text: 'SPEC1',
          align: 'center',
          sortable: false,
          value: 'spec1',
        },
        {
          text: 'FAMILYNAME',
          align: 'center',
          sortable: false,
          value: '',
        },
        {
          text: 'SPEC2 ' + this.$t('MANAGE_SHANIV.MATERIAL'),
          // text: 'VATPRICE',
          align: 'center',
          sortable: false,
          value: '',
        },
        {
          text: 'SPEC13 ' + this.$t('GIFTCARD_PAYMENT_RESULT.QUANTITY'),
          // text: 'SHAN_BARCODE',
          align: 'center',
          sortable: false,
          value: '',
        },
        {
          text: 'SPEC14 ' + this.$t('MANAGE_SHANIV.BRAND'),
          align: 'center',
          sortable: false,
          value: 'spec14',
        },
        {
          text: 'BARCODE',
          align: 'center',
          sortable: false,
          value: 'SHAN_BARCODE',
        },
        {
          text: 'SPEC15 ' + this.$t('MANAGE_SHANIV.PARKING'),
          align: 'center',
          sortable: false,
          value: 'spec15',
        },
        {
          text: 'NSCUST',
          align: 'center',
          sortable: false,
          value: 'nscust',
        },
        {
          text: 'BALANCE',
          align: 'center',
          sortable: false,
          value: 'BALANCE_original',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'add',
        },
      ],
      test: {
        options: ['test1', 'test2'],
        selected: 'test1',
      },
      start_hour: {
        options: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ],
        selected: '16:00',
      },
      end_hour: {
        options: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ],
        selected: '14:00',
      },
      previewImage: "",
      isPreview: false,
      image: "",
      company: {
        options: [],
        selected: ""
      },
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
    };
  },
  async created() {
    await this.getCompanyList(); 
    if (this.companyList.length) {
      this.company.options = this.companyList.map(comp => comp.name);
      this.company.selected = this.company.options[0];
    }
    if (this.$route.name == "ShanivPackage") {
      await this.getPackageData({ id: this.packageId });
      if (this.package_data.image != "") {
        this.isPreview = true;
        this.previewImage = this.package_data.image;
      }
      if (this.package_data.company) {
        this.company.selected = this.package_data.company;
      }
    } 
    await this.getProductsList(1);
  },
  methods: {
    ...mapActions('shaniv_management', {
        getProducts: 'getProducts',
        getPackageData: 'getPackageData',
        updatePackageData: 'updatePackageData',
        getCompanyList: 'getCompanyList',
    }),    
    /* add and remove product */
    addProduct(id) {
      for ( let i=0; i<this.products_data.data.length; i++) {
        if (this.products_data.data[i].id == id) {
          let newProduct = {
            "product_id": this.products_data.data[i].id,
            "PARTNAME": this.products_data.data[i].PARTNAME,
            "free_text": this.products_data.data[i].PARTDES,
            "quantity": this.products_data.data[i].QUANT,
            "image": this.products_data.data[i].image,
          }
          this.package_data.products.push(newProduct);
          break;
        }
      }
    },
    removeProduct(index) {
      this.package_data.products.splice(index, 1);
    },

    /* update package data */
    async updatePackage() {
      this.package_data.products.forEach(item => {
        delete item.ShanivProduct;
        delete item.SHAN_BARCODE;
        delete item.image;
      });
      await this.updatePackageData({ id: this.packageId });

      if (this.$route.name == "ShanivPackage") {
        await this.getPackageData({ id: this.packageId });
        if (this.package_data.image != "") {
          this.isPreview = true;
          this.previewImage = this.package_data.image;
        }
      } 
    },
    /* file upload */
    handleImage(e) {
      this.package_data.image = this.$refs.uplaod_file.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      this.isPreview = true;
      reader.onload = e =>{
        this.previewImage = e.target.result;
      };
    },
    /* end file upload */
    changeCompany() {
      this.package_data.company = this.company.selected;
      this.getProductsList(1);
      this.package_data.products = [];
    },
    getProductsList(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      if (this.company.selected) {
        this.getProducts({
          per_page: itemsPerPage, 
          page: page-1, 
          company: this.company.selected,
          search_term: this.search,
        });
      }
    },
    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getProductsList(1);
      }, 1000)
    }
  },
  watch: {
    options: {
        handler () {
          this.getProductsList();
        },
        deep: true,
    },
  },
};

</script>
<style lang="scss" scoped>
.filter-part {
  .input {
    height: 40px;
  }
  .form-item {
    margin: 0px 10px;
    max-width: 240px;
  }
  .filter-form-group {
    padding: 0px 10px;
    max-width: 900px;
    width: 100%;
    .type-operation {
      width: 20%;
    }
    .operation-name {
      width: 50%;
    }
    .price-div {
      width: 20%;
    }
    .date-selector {
      width: calc(30% - 20px);
    }
    .time-selector {
      width: calc(20% - 20px);
    }
  }
  .calendar-img {
    width: 24px;
    position: absolute;
    top: 7px;
    left: 7px;
  }
  .clock-img {
    width: 22px;
    position: absolute;
    top: 9px;
    left: 8px;
  }
  .product-form {
    .minus-btn {
      background-color: #BE1E2D;
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .product-img {
      width: 40px;
      margin: 0 10px;
    }
    .product-desc {
      width: 100%;
      margin: 0 20px;
    }
    .product-number {
      width: 80px;
    }
  }
  .upload-part {
    width: 340px;
    font-size: 16px;
    min-width: 290px;
    p {
      margin: 0;
    }
    .select-img_btn {
      width: auto;
      height: 36px;
      padding: 0 15px;
      font-size: 16px;
    }
    .upload-block {
      border: 1px solid #234395;
      border-radius: 7px;
      .upload-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        background-color: #fff;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background-size: contain;
        background-position: center;
        img {
          width: 100px;
        }
      }
      .add-basket_btn {
        border-radius: 20px;
        height: 38px;
        width: auto;
        padding:  20px;
        font-size: 16px;
      }
      .upload-bottom {
        background-color: #D4E0FF;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        .cart-num {
          position: relative;
          .circle-btn {
            position: absolute;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
          }
          .plus-circle {
            right: 9px;
          }
          .minus-circle {
            left: 9px;
          }
          input {
            background-color: #fff;
            width: 60px;
            text-align: center;
            margin: 0 20px;
          }
        }
      }
    }
  }
}
.plus-btn {
  display: flex;
  justify-content: center;
  background-color: #234395;
  border-radius: 2px;
  padding: 2px;
  margin: 0 auto;
  width: fit-content;
}

.bottom-action {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px;
  .primary-btn {
    margin: 0 0 15px 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}

.ltr-type {
  .filter-part {
    .calendar-img {
      left: unset;
      right: 7px;
    }
    .clock-img {
      left: unset;
      right: 8px;
    }
  }  
}
@media screen and (max-width: 1060px) {
  .form-block{
    flex-wrap: wrap;
    .operation-name,
    .type-operation,
    .date-selector,
    .time-selector {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .table-content-part {
    margin: 20px 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .filter-part {
    flex-wrap: wrap;
  }
  .filter-form-group {
    padding-left: 0 !important;
  }
  .filter-part .upload-part {
    margin: 0 auto;
  }
}
</style>